import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const quickFilters = "images/quickFilters.webp";

export default function FAQ(props) {
  const isMobileView = window.innerWidth < 600;
  return (
    <Box sx={{ padding: isMobileView ? "16px 20px" : "60px 200px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Tooltip title="GO BACK">
          <IconButton
            aria-label="delete"
            sx={{ mr: "16px" }}
            onClick={(e) => {
              props?.history?.goBack();
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Tooltip>

        <Typography
          variant={isMobileView ? "h5" : "h4"}
          color={"#000000"}
          fontWeight="bold"
          textAlign={"left"}
          mt={isMobileView ? "1em" : ""}
        >
          FAQs: Updated on 28-Oct-2022
        </Typography>
      </Box>
      <Divider
        sx={{
          borderColor: "rgba(102, 51, 153, 0.3)",
          my: "16px",
          mb: "30px",
        }}
      />
      <Typography
        variant="h5"
        color={"#000000"}
        fontWeight="bold"
        textAlign={isMobileView ? "center" : "left"}
        mb="1em"
      >
        Registration
      </Typography>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            How to register?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Visit the home page https://vvs.srijspn.org You can choose to
            “REGISTER FOR FREE” on the home page.
          </Typography>
          <br></br>
          <Typography fontWeight={600}>Free registration</Typography>

          <Typography>
            {" "}
            Provide Name, DoB, Gender, e-mail ID, Mobile # Click on “Verify Me”
            and your email will be verified with OTP followed by Mobile
            verification. After verification, you will be asked to provide your
            profile details.
          </Typography>
          <br></br>
          <Typography fontWeight={600}>Paid registration</Typography>

          <Typography>
            {" "}
            There is ‘no paid service’ as the VVS website service is a
            non-commercial venture.
          </Typography>
          <br></br>
          <Typography>
            {" "}
            However, we have introduced a complimentary feature of VVS Prime
            which will be enabled for all donors who donate an amount of Rs 500
            and above. This feature will be valid for a period of 6 months and
            provides instant access to contact details of matches for whom you
            have expressed interest
          </Typography>
          <Typography>
            The donations collected through VVS Prime are used to maintain the
            VVS website and manage its operating expenses as well as to fund
            other social service activities under the JSPN umbrella.
          </Typography>
          <Typography>
            You shall have a 'Matrimony ID' generated for future reference and
            login purposes. This will be your unique identifier on the VVS
            platform.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Which is my region of stay?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you are staying in Karnataka, you need to select appropriate
            region so that your profile will be properly filtered. If you are
            outside Karnataka select the corresponding region.
          </Typography>
          <br></br>
          <Typography fontWeight={600}>
            Below are the districts of Belagavi Division{" "}
          </Typography>

          <Typography>
            {" "}
            Bagalkote, Belagavi, Dharwada, Gadaga, Haveri, Uttara Kannada and
            Vijayapura.
          </Typography>
          <br></br>
          <Typography fontWeight={600}>
            Below are the districts of Bengaluru Division{" "}
          </Typography>

          <Typography>
            {" "}
            Bengaluru Rural, Bengaluru Urban, Chikkaballapura, Chitradurga,
            Davanagere, Kolar, Ramanagara, Shivamogga and Tumakuru.
          </Typography>
          <br></br>
          <Typography fontWeight={600}>
            Below are the districts of Kalaburgi Division{" "}
          </Typography>

          <Typography>
            {" "}
            Ballari, Bidar, Kalaburagi, Koppala, Raichuru, Vijayanagara and
            Yadagiri.
          </Typography>
          <br></br>
          <Typography fontWeight={600}>
            Below are the districts of Mysuru Division{" "}
          </Typography>

          <Typography>
            {" "}
            Chamarajanagara, Chikkamagaluru, Dakshina Kannada, Hassan, Kodagu,
            Mandya, Mysurua and Udupi.
          </Typography>
          <br></br>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Are profiles Validated?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            All the profiles are validated before inclusion in the database by
            Email and Mobile OTP. Our support team meticulously validates each
            and every profile carefully on specific criterion at regular
            intervals. Additionally, users can also join hands by reporting
            their concerns, if any, about the information provided in the
            profiles listed under My Matches.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            How are profiles validated?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Almost every word counts. We reject profiles <br></br>
            <br></br>1. Created by underage persons (as per legal requirement)
            <br></br>2. That contain abusive content
            <br></br>3. Contain invalid & incoherent data
            <br></br>4. Created for purposes other than first marriage.
          </Typography>
          <br></br>
          <Typography>
            It is our endeavour to ensure that only genuine profiles are made
            available to users of this website. And we solicit your support in
            this regard by requesting you to report any factually incorrect or
            misleading profiles that you may come across during your match
            seeking process.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          marginTop: "0.5em",
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Registrations - Multiple Profiles
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Can I register on behalf of someone else?
            <br></br>Yes, you can register on behalf of someone else.
            <br></br>
            <br></br>Can I register for my sister/brother and myself with the
            same Matrimony ID?
            <br></br>A Matrimony ID identifies each person uniquely. Please
            register separate profiles for you and your sibling for individual
            partner searches.
            <br></br>
            <br></br>Can I register for my friend, relative or sibling?
            <br></br>
            Yes! In fact, we have a large number of profiles being registered by
            parents, siblings, relatives, etc.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Can I use the same e-mail ID for multiple registrations?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            No. To avoid misuse by non-genuine or commercial entities we have a
            hard limit of one profile per email id and mobile number. You can
            use different email/ mobile number for each profile.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          marginTop: "0.5em",
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            My profile was deleted. Can I register the same profile?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Once your profile is deleted all your profile data , matches, inbox
            data etc are cleared. You can register afresh and start again.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            What all information from my profile will be displayed to other
            members?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            All data except contact information of matched profiles is visible
            to all registered users.
            <br></br> <br></br>
            Contact numbers of profiles are visible only for all interests which
            are accepted by other party. For VVS prime users, instant access to
            contact information of profile is provided once an interest is
            expressed for that profile
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            My profile was deleted. Can I register the same profile?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Once your profile is deleted all your profile data , matches, inbox
            data etc are cleared. You can register afresh and start again.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            What all information from my profile will be displayed to other
            members?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            All data except contact information of matched profiles is visible
            to all registered users.
            <br></br> <br></br>
            Contact numbers of profiles are visible only for all interests which
            are accepted by other party. For VVS prime users, instant access to
            contact information of profile is provided once an interest is
            expressed for that profile
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            What is Mobile Number Verification?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We only display 100% verified mobile numbers. So, unless your mobile
            number is verified you would not be able to use this service. The
            verification process is done free of cost. Only VVS Prime members
            can view your mobile number once they express their interest of
            alliance. For non VVS Prime numbers your mobile number will be
            visible only once you accept their interest
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            How do I change my Email and phone and verify them?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Login into website and click My Account option available under the
            icon visible at the top Right corner of the website.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            How do I Delete my profile?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Login into website and click on My Account or Delete account option
            available under the icon visible at the top Right corner of the
            website.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            How long is my profile stored in the system?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            All profiles which are active for less than or equal to 6 months
            will be retained in system . Any profile user who does not login
            once in 6 months (180 days) will be deleted from system post
            periodic reminders.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            My profile was deleted. Can I recover my profile?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Once your profile is deleted all your profile, matches, inbox data
            is cleared and cannot be recovered. You can register afresh and
            start again.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography
        variant="h5"
        color={"#000000"}
        fontWeight="bold"
        textAlign={isMobileView ? "center" : "left"}
        mt="1em"
      >
        Profile creation / modification
      </Typography>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            How do I update the date of birth and gender which was wrongly
            entered?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You don’t have an option to update these two mandatory data fields
            once the profile is created. Please write to jspn.vvs@umapps.in. The
            Admin team with update these fields from the backend and regenerate
            matches for you.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            How do I create/modify a profile?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            During the Registration you will be taken to the profile section to
            enter all data once you verify your email and mobile. <br></br>
            You can also visit “My Profile” to update the profile anytime later.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Photos
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You have to upload one profile photo which is mandatory during the
            process of registration. Any additional photo is optional. You can
            change them by visiting Profile section of website.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Horoscope/kundali
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You have option to upload the photo of your kundali/horoscope which
            is visible to all matched profiles. So upload it only if you are
            willing to share it. You do not have control to hide it as of now.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Photos/Horoscope deletion
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Visit Edit Profile from Profile section to delete additional photo
            and horoscope.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Do’s and Don’ts in my profile
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Here are the Do's and Don'ts while creating your profile to give
            members the best portrayal of yourself. <br></br>
            <br></br>
            Do's <br></br>
            While creating a matrimonial profile, give brief information about
            yourself in the profile description part. Try your best to touch
            upon various aspects of your life like: <br></br>
            Your personality (what kind of a person you are, your likes and
            dislikes). <br></br>
            Your educational and professional background including your
            achievements. <br></br>
            Your family (how many members, family values - conservative or
            modern). <br></br>
            Your career plans. <br></br>
            Your expectations from your soul mate. <br></br>
            Add a recent photograph to improve chances of getting responses.{" "}
            <br></br>
            Key to Success: Profiles with clear, relevant photos are more likely
            to receive proposals. <br></br>
            <br></br>
            Don'ts <br></br>
            Please do not give a lengthy description. Try to keep your
            description short and complete. <br></br>
            Please do not try to display your contact details in your
            matrimonial profile, except in the space provided for contact
            details. <br></br>
            Do not use generic names like Vadhu, Vara, Bride, Groom etc. Such
            profiles will be deleted. <br></br>
            Do not upload obscene/fake/unclear photos. They will be deleted and
            profile will be deactivated. <br></br>
            Please see our terms of use/service agreement for more details of
            what type of content is prohibited on our website. <br></br>
            Not conforming to any of the above can lead to your profile being
            rejected.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography
        variant="h5"
        color={"#000000"}
        fontWeight="bold"
        textAlign={isMobileView ? "center" : "left"}
        mt="1em"
      >
        Login Details
      </Typography>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            How do I login?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You have to visit the website and click “Login”. <br></br>
            <br></br>
            You have options to either login with Login Id which can be either
            your registered Email/ Mobile number and password. <br></br>
            You can login with Mobile number and Mobile OTP only 3 times a day.{" "}
            <br></br>
            You can login with Email and Email OTP any number of times.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            How should I login if I have forgotten my password?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Either you can reset password using{" "}
            <strong>Forgot Password </strong>option on the login page or Login
            with Mobile/Email OTP.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            How do I change my password?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            There are 2 ways to change your password <br></br>
            1. Go to Login page and use forgot password option to change.{" "}
            <br></br>
            2. Login to your account and click “My account” option available
            under the profile icon at the top right corner. You have option to
            change password there.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography
        variant="h5"
        color={"#000000"}
        fontWeight="bold"
        textAlign={isMobileView ? "center" : "left"}
        mt="1em"
      >
        Matches and Search profiles
      </Typography>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            What are my matches?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            These are list of partner profiles listed based on following
            criteria: <br></br>
            1. Gotra, Gana Matching<br></br>
            2. Gunas matching with minimum of 18 Guna<br></br>
            3. Grihamaitri of 2.5 and above which is important.<br></br>
            4. No Ekanadi dosha.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            How are matches generated and what is the basis?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Matches are programmatically generated as per the Uttaradimath
            Koshtaka and guidelines published Uttardimath Panchanga.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            When can I see matches ? How long I have to wait?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            System will show you matches immediately after completing your
            profile in the system. There is no dependency on anybody and you
            need not wait for any.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Will the PRIME and non-PRIME member see different matches?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            NO. You will see the same matches irrespective of the type of user.
            PRIME feature only enables the instant access to contacts of the
            profiles you have expressed interest. Rest all features are same for
            PRIME and non-PRIME member.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Can I add quick filters on top of above?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we have provided quick filters to choose from most recent
            profiles, height, same language, similar education. However, this
            option is available only on Desktop View and not on Mobile Website.
          </Typography>
          <img src="images/quickFilters.webp" style={{ width: "80vw" }}></img>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Can I Search by name and profile id?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, you can search but within matched profiles only and not outside
            your matches.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            What all filter options I get?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Under Add more filters you can opt for filters related to recently
            created, age, language, education, exclude/include ekanadi,
            education, height, exclude contacted profiles.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Can I shortlist the matched profile for further action?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Currently the feature is not available. It will be made available
            soon.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Whom should we contact if we need more clarifications on matches
            related issues?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Please write to support email <strong>jspn.vvs@umapps.in</strong>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Are matches completely reliable?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Matches are reliable most of the times. It depends on the data
            entered by users. So we request users to manually verify and consult
            astrologer once things are set in right direction with the help from
            our VVS Platform.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography
        variant="h5"
        color={"#000000"}
        fontWeight="bold"
        textAlign={isMobileView ? "center" : "left"}
        mt="1em"
      >
        Ways to Contact
      </Typography>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Express Interest
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            This is the only way to establish the communication to possible
            prospect. Upon acceptance of interest, the contact details are
            shared. <br></br>
            For VVS prime users the instant access to contact information of
            profile is provided once an interest is expressed for that profile.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            How to express interest to a profile
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Go to My matches, search for the profile , review each profile, on
            each profile you have option to express interest.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            I have expressed interest. What next?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Once you express interest a communication will be send and basic
            profile details will be share, once the prospect accepts your
            invitation contact information will be shared. You will have to wait
            until then.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Can I get instant access to contacts?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Only VVS prime users who expresses interest to profile can access
            contacts instantly.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            How do I send reminder to prospects who’s interests are still in
            pending state?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The system will send the reminders to all profiles whose requests
            are in pending State once in a week. So we take care of your
            efforts.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            I declined a request for a profile by mistake what should I do?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can't revert it yourself, Please write to support email{" "}
            <strong>jspn.vvs@umapps.in</strong>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Typography
        variant="h5"
        color={"#000000"}
        fontWeight="bold"
        textAlign={isMobileView ? "center" : "left"}
        mt="1em"
      >
        Miscellaneous
      </Typography>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Do we have any helpdesk?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes we do have a team which address concerns within 8-10hrs
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            Whom and how should we contact support?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Email (jspn.vvs@umapps.in)and chatbot on websites are only 2 ways of
            approaching the support team.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{
          border: "1px solid #663399",
          borderRadius: "1em",
          color: "#f1f3f6",
          marginTop: "0.5em",
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
            We have a concern about a profile. How to raise it?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            On every profile under My matches you have an option to report issue
            about profile. You have a category to choose and provide details
            which will logged and reviewed by the VVS team and appropriate
            action would be taken. Alternately you wan write to
            jspn.vvs@umapps.in
          </Typography>
          <Typography>
            <img
              src="images/report_profile.webp"
              style={{ width: isMobileView ? "80vw" : "60vw" }}
            ></img>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
