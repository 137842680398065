import * as constants from "../constants";

export const fetchMyMatches = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/matching-profiles",
    method: "GET",
    params: data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const fetchUserStats = (onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: "/invitation-stats",
    method: "GET",
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const fetchMyMatchesDetail = (matchedUserId, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: `/all-details/${matchedUserId}`,
    method: "GET",
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const AcceptMatchInvite = (matchedUserId, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: `/accept-invite/${matchedUserId}`,
    method: "POST",
    data: { matchedUserId },
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const CancelMatchInvite = (matchedUserId, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: `/cancel-invite/${matchedUserId}`,
    method: "POST",
    data: { matchedUserId },
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const FetchMatchInvite = (type, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: `/get-invites`,
    method: "GET",
    params: { ...type },
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const RejectMatchInvite = (matchedUserId, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: `/reject-invite/${matchedUserId}`,
    method: "POST",
    data: { matchedUserId },
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const SendMatchInvite = (matchedUserId, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: `/send-invite/${matchedUserId}`,
    method: "POST",
    data: { matchedUserId },
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const ReportProfile = (matchedUserId, data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: `/report-profile/${matchedUserId}`,
    method: "POST",
    data: data,
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});
export const FetchContacts = (matchedUserId, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: `/get-contact/${matchedUserId}`,
    method: "GET",
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const FetchPrimeContacts = (matchedUserId, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    url: `/get-prime-contact/${matchedUserId}`,
    method: "GET",
    success: (response) => {
      return {
        type: constants.DO_NOTHING,
      };
    },
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});