import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ShareIcon from "../../Assets/images/share.png";
import {
  AcceptMatchInvite,
  CancelMatchInvite,
  FetchContacts,
  FetchPrimeContacts,
  RejectMatchInvite,
} from "../../Store/Actions/matchesActionCreator";
import { EMPTY_VALUE, INVITE_STATUS_ENUM } from "../../Utils/constants";
import { showMessage } from "../../Utils/helper";
import InviteSharedInfoModal from "../Modals/ModalContexts/InviteSharedInfoModal";
import { Button, Spinner } from "../UI";
import "./style.scss";
import { useConfirm } from "../ConfirmationModal";

function InboxUserCard({ user, ...props }) {
  const isMobile = window.innerWidth < 600;
  const [loading, setloading] = useState(false);
  const [contactResp, setContactResp] = useState(null);
  const [isInviteSharedModalOpen, toggleInviteSharedModal] = useState(false);

  const ConfirmationModal = useConfirm();

  const CancelInvite = () => {
    if (!!props?.data?.id) {
      ConfirmationModal({
        title: "Are you sure?",
        description: "You want to cancel this invite.",
        confirmationText: "Yes",
        confirmationButtonProps: {
          variant: "contained",
          style: {
            // backgroundColor: '',
            color: "white",
          },
        },
        cancellationButtonProps: {
          style: {
            // backgroundColor: '',
            color: "red",
          },
        },
        cancellationText: "No",
      }).then(() => {
        setloading(true);
        props?.dispatchCancelMatchInvite(
          props?.data?.id,
          (resp) => {
            if (resp?.status) {
              showMessage(resp?.result, "success");
            } else {
              showMessage(resp?.result, "error");
            }
            setloading(false);
            props?.setCategoryType(""); //This is done to refresh the page once invite is rejected/Accepted
          },
          (err) => {
            setloading(true);
            showMessage("Something went wrong!", "error");
          }
        );
      });
    }
  };

  const fetchPrimeContacts = () => {
    if (!!props?.data?.id) {
      setloading(true);
      props?.dispatchFetchPrimeContacts(
        props?.data?.id,
        (resp) => {
          if (resp?.status) {
            showMessage("success", "success");
            setContactResp(resp?.result);
            toggleInviteSharedModal(true);
          } else {
            showMessage(resp?.result, "error");
          }
          setloading(false);
        },
        (err) => {
          setloading(true);
          showMessage("Something went wrong!", "error");
        }
      );
    }
  };

  const fetchContacts = () => {
    if (!!props?.data?.id) {
      setloading(true);
      props?.dispatchFetchContacts(
        props?.data?.id,
        (resp) => {
          if (resp?.status) {
            showMessage("success", "success");
            setContactResp(resp?.result);
            toggleInviteSharedModal(true);
          } else {
            showMessage(resp?.result, "error");
          }
          setloading(false);
        },
        (err) => {
          setloading(true);
          showMessage("Something went wrong!", "error");
        }
      );
    }
  };
  const AcceptInvite = () => {
    if (!!props?.data?.id) {
      setloading(true);
      props?.dispatchAcceptMatchInvite(
        props?.data?.id,
        (resp) => {
          if (resp?.status) {
            showMessage(resp?.result, "success");
          } else {
            showMessage(resp?.result, "error");
          }
          setloading(false);
          props?.setCategoryType("");
        },
        (err) => {
          setloading(true);
          showMessage("Something went wrong!", "error");
        }
      );
    }
  };
  const RejectInvite = () => {
    if (!!props?.data?.id) {
      ConfirmationModal({
        title: "Are you sure?",
        description: "You want to decline this invite.",
        confirmationText: "Yes",
        confirmationButtonProps: {
          variant: "contained",
          style: {
            // backgroundColor: '',
            color: "white",
          },
        },
        cancellationButtonProps: {
          style: {
            // backgroundColor: '',
            color: "red",
          },
        },
        cancellationText: "No",
      }).then(() => {
        setloading(true);
        props?.dispatchRejectMatchInvite(
          props?.data?.id,
          (resp) => {
            if (resp?.status) {
              showMessage(resp?.result, "success");
            } else {
              showMessage(resp?.result, "error");
            }
            setloading(false);
            props?.setCategoryType("");
          },
          (err) => {
            setloading(true);
            showMessage("Something went wrong!", "error");
          }
        );
      });
    }
  };
  const newProps = {
    ...props,
    isInviteSharedModalOpen,
    toggleInviteSharedModal,
    contactResp,
    loading,
    CancelInvite,
    RejectInvite,
    AcceptInvite,
    fetchContacts,
    fetchPrimeContacts,
    user,
  };
  return (
    <>
      {isMobile ? <Mobile {...newProps} /> : <Desktop {...newProps} />}{" "}
      {!!isInviteSharedModalOpen && (
        <InviteSharedInfoModal
          open={isInviteSharedModalOpen}
          data={contactResp}
          onClose={() => {
            toggleInviteSharedModal(false);
          }}
          next={() => {
            toggleInviteSharedModal(false);
          }}
        />
      )}
    </>
  );
}

function Desktop({ user, data, type, ...props }) {
  const ShareActionContext = (
    <CardContent
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "20%",
        position: "relative",
        height: "10em",
        backgroundColor: "rgba(0,170,68,0.25)",
        cursor: "pointer",
      }}
      onClick={() => {
        props?.fetchContacts();
      }}
    >
      <>
        <Box
          sx={{
            padding: 2.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img alt="ShareIcon" style={{ width: 50 }} src={ShareIcon} />
        </Box>
        <Typography
          fontSize={"small"}
          sx={{
            width: "100%",
            fontWeight: "bolder",
            textAlign: "center",
            color: "#000",
          }}
        >
          VIEW CONTACT
        </Typography>
      </>
    </CardContent>
  );

  const PrimeUserActionContext = (
    <CardContent
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "20%",
        position: "relative",
        height: "10em",
        backgroundColor: "rgba(0,170,68,0.25)",
        cursor: "pointer",
      }}
      onClick={() => {
        props?.fetchContacts();
      }}
    >
      <>
        <Box
          sx={{
            padding: 2.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <img alt="ShareIcon" style={{ width: 50 }} src={ShareIcon} />
        </Box>
        <Typography
          fontSize={"small"}
          sx={{
            width: "100%",
            fontWeight: "bolder",
            textAlign: "center",
            color: "#000",
          }}
        >
          VIEW CONTACT
        </Typography>
      </>
    </CardContent>
  );

  const RejectedActionContext = (
    <CardContent
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "20%",
        position: "relative",
        height: "10em",
        // backgroundColor: "rgba(0,170,68,0.25)",
        cursor: "pointer",
      }}
      onClick={() => {}}
    >
      <Typography
        fontSize={"large"}
        sx={{
          width: "100%",
          fontWeight: "bolder",
          textAlign: "center",
          color: "red",
        }}
      >
        DECLINED
      </Typography>
    </CardContent>
  );
  return (
    <Spinner loading={props?.loading}>
      <Card
        variant="outlined"
        sx={{
          minWidth: 275,
          borderColor: "#707070",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "85%",
            height: "10em",
            position: "relative",
            borderRight: "1px solid #000",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            {!!Object.keys(data?.imageUrl)[0] ? (
              <Avatar
                alt={data?.firstName ?? "a"}
                sx={{ width: 100, height: 100, objectFit: "cover" }}
                src={data?.imageUrl[Object.keys(data?.imageUrl)[0]]}
              />
            ) : (
              <Avatar
                alt={data?.firstName ?? "a"}
                sx={{ width: 100, height: 100, objectFit: "cover" }}
                src={EMPTY_VALUE}
              />
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-around",
                flexDirection: "column",
                width: "100%",
                marginLeft: 3,
              }}
            >
              <Typography
                variant="h7"
                className="responsive_name_text"
                sx={{ mt: 1.5, fontWeight: "bold", textTransform: "uppercase" }}
                color={"text.primary"}
                gutterBottom
                title={`${data?.firstName ?? ""} ${data?.lastName ?? ""}`}
              >
                {(() => {
                  const name = `${data?.firstName ?? ""} ${
                    data?.lastName ?? ""
                  }`;
                  return name?.length < 25
                    ? name ?? EMPTY_VALUE
                    : `${data?.firstName ?? EMPTY_VALUE} ${
                        data?.lastName ?? EMPTY_VALUE
                      }`.slice(0, 22) + "...";
                })()}
              </Typography>
              <Typography color="black" variant="subtitle2" fontWeight={600}>
                {data?.location ?? "-"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{ width: "80%" }}
                  variant="caption"
                  color="#000000"
                >
                  Education
                </Typography>
                <Typography
                  sx={{ width: "5%" }}
                  variant="caption"
                  color="#000000"
                >
                  {" : "}
                </Typography>
                <Typography
                  sx={{ width: "80%", fontWeight: "bolder" }}
                  variant="caption"
                  color="#000000"
                >
                  {" " + data?.higherQualification ?? EMPTY_VALUE}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{ width: "80%" }}
                  variant="caption"
                  color="#000000"
                >
                  Age
                </Typography>
                <Typography
                  sx={{ width: "5%" }}
                  variant="caption"
                  color="#000000"
                >
                  {" : "}
                </Typography>
                <Typography
                  sx={{ width: "80%", fontWeight: "bolder" }}
                  variant="caption"
                  color="#000000"
                >
                  {data?.age ?? EMPTY_VALUE}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-around",
              flexDirection: "column",
              marginRight: 3,
            }}
          >
            <Typography
              fontFamily="Roboto"
              fontWeight={600}
              sx={{ my: 1.5 }}
              color={"text.primary"}
            >
              <div
                style={{
                  borderBottom: "1px solid black",
                  display: "inline-block",
                }}
              >
                Profile ID:{data?.id ?? EMPTY_VALUE}
              </div>
            </Typography>
            <Button
              variant="contained"
              style={{
                height: "2.5em",
                fontWeight: 900,
                margin: "3em 0",
                fontSize: "10pt",
                displat: "block",
              }}
              size="large"
              disableElevation={true}
              onClick={() => {
                if (!!data?.id) {
                  props.history.push(
                    `/my-matches/${data?.firstName?.replace(" ", "-")}`,
                    {
                      data,
                      pathname: "/my-inbox/",
                      filterInfo: props?.filterPayload,
                    }
                  );
                }
              }}
            >
              Visit PROFILE
            </Button>
          </Box>
        </CardContent>
        {type === INVITE_STATUS_ENUM.RECEIVED ? (
          data?.invitationStatus === INVITE_STATUS_ENUM.USER_ACCEPTED ||
          data?.invitationStatus === INVITE_STATUS_ENUM.SYSTEM_ACCEPTED ? (
            ShareActionContext
          ) : data?.invitationStatus === INVITE_STATUS_ENUM.REJECTED ? (
            RejectedActionContext
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                width: "20%",
                height: "10em",
                position: "relative",
              }}
            >
              <Button
                variant="text"
                sx={{
                  color: "#00AA44",
                  fontWeight: "bold",
                  padding: 3,
                }}
                fullWidth
                disableElevation={true}
                onClick={() => {
                  props?.AcceptInvite();
                }}
              >
                ACCEPT
              </Button>
              <Divider sx={{ width: "100%", borderColor: "#000" }} />
              <Button
                variant="text"
                sx={{
                  color: "#F86969",
                  fontWeight: "bold",
                  padding: 3,
                }}
                fullWidth
                disableElevation={true}
                onClick={() => {
                  props?.RejectInvite();
                }}
              >
                Decline
              </Button>
            </Box>
          )
        ) : data?.invitationStatus === INVITE_STATUS_ENUM.USER_ACCEPTED ||
          data?.invitationStatus === INVITE_STATUS_ENUM.SYSTEM_ACCEPTED ? (
          ShareActionContext
        ) : data?.invitationStatus === INVITE_STATUS_ENUM.REJECTED ? (
          RejectedActionContext
        ) : (
          // <CardContent
          //   sx={{
          //     // display: "flex",
          //     alignItems: "center",
          //     justifyContent: "center",
          //     flexDirection: "column",
          //     width: "20%",
          //     position: "relative",
          //     height: "10em",
          //     backgroundColor: "rgba(248,105,105, 0.40)",
          //     cursor: "pointer",
          //     marginTop: "0px"
          //   }}
          //   onClick={() => {
          //     props?.CancelInvite();
          //   }}
          // >
          //   <Typography
          //     fontSize={"large"}
          //     sx={{
          //       width: "100%",
          //       fontWeight: "bolder",
          //       textAlign: "center",
          //       color: "#fff",
          //       backgroundColor: "rgba(248,105,105, 0.40)",
          //     }}
          //   >
          //     CANCEL REQUEST
          //   </Typography>
          //   <Divider sx={{ width: "100%", borderColor: "#000" }} />
          //   <Typography
          //     fontSize={"large"}
          //     sx={{
          //       width: "100%",
          //       fontWeight: "bolder",
          //       textAlign: "center",
          //       color: "#fff",
          //       backgroundColor: "wheat",
          //     }}
          //   >
          //     VIEW CONTACT
          //   </Typography>
          // </CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
              width: "20%",
              height: "10em",
              position: "relative",
            }}
          >
            <Button
              variant="text"
              sx={{
                color: "#F86969",
                fontWeight: "bold",
                padding: 3,
              }}
              fullWidth
              disableElevation={true}
              onClick={() => {
                props?.CancelInvite();
              }}
            >
              CANCEL
            </Button>

            <Divider sx={{ width: "100%", borderColor: "#000" }} />
            <Tooltip title="Only for PRIME Users" arrow>
              <div>
                <Button
                  variant="text"
                  sx={{
                    color: "#00AA44",
                    fontWeight: "bold",
                    padding: 3,
                  }}
                  disabled={!user?.prime}
                  fullWidth
                  disableElevation={true}
                  onClick={() => {
                    props?.fetchPrimeContacts();
                  }}
                >
                  VIEW CONTACT
                </Button>
              </div>
            </Tooltip>
          </Box>
        )}
      </Card>
    </Spinner>
  );
}

function Mobile({ user, data, type, ...props }) {
  const CancelRequestActionContext = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        position: "relative",
        width: "100%",
      }}
    >
      <Button
        variant="text"
        sx={{
          color: "red",
          // fontWeight: "bold",
          borderRight: "1px solid #fff",
        }}
        fullWidth
        disableElevation={true}
        onClick={(e) => {
          e.stopPropagation();
          props?.CancelInvite();
        }}
      >
        CANCEL REQUEST
      </Button>
      <Tooltip title="Only for PRIME Users" arrow>
        <Button
          variant="text"
          sx={{
            color: "#00AA44",
            fontWeight: "bold",
          }}
          disabled={!user?.prime}
          fullWidth
          disableElevation={true}
          onClick={(e) => {
            e.stopPropagation();
            props?.fetchPrimeContacts();
          }}
        >
          VIEW CONTACT
        </Button>
      </Tooltip>
    </Box>
  );
  const ShareActionContext = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        position: "relative",
        width: "100%",
      }}
    >
      <Button
        variant="text"
        sx={{
          color: "#00AA44",
          fontWeight: "bold",
          borderRight: "1px solid #fff",
        }}
        fullWidth
        disableElevation={true}
        onClick={(e) => {
          e.stopPropagation();
          props?.fetchContacts();
        }}
      >
        VIEW CONTACT
      </Button>
    </Box>
  );
  const RejectedActionContext = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
        position: "relative",
        width: "100%",
      }}
    >
      <Button
        variant="text"
        sx={{
          color: "red",
          fontWeight: "bold",
          borderRight: "1px solid #fff",
        }}
        fullWidth
        disabled={true}
        disableElevation={true}
      >
        DECLINED
      </Button>
    </Box>
  );
  return (
    <Spinner loading={props?.loading}>
      <Card
        variant="outlined"
        sx={{
          minWidth: 300,
          backgroundColor: "#FFE9F8",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          flexDirection: "column",
          width: "100%",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.25)",
        }}
        elevation={5}
      >
        <CardContent
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "row",
            position: "relative",
          }}
          onClick={() => {
            if (!!data?.id) {
              props.history.push(
                `/my-matches/${data?.firstName?.replace(" ", "-")}`,
                {
                  data,
                  pathname: "/my-inbox/",
                  filterInfo: props?.filterPayload,
                }
              );
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            {!!Object.keys(data?.imageUrl)[0] ? (
              <Avatar
                alt={data?.firstName ?? "a"}
                sx={{ width: 100, height: 100, objectFit: "cover" }}
                src={data?.imageUrl[Object.keys(data?.imageUrl)[0]]}
              />
            ) : (
              <Avatar
                alt={data?.firstName ?? "a"}
                sx={{ width: 100, height: 100, objectFit: "cover" }}
                src={EMPTY_VALUE}
              />
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-around",
                flexDirection: "column",
                marginLeft: 2,
              }}
            >
              <Typography
                sx={{
                  mt: 1.5,
                  fontWeight: "900",
                  textTransform: "uppercase",
                  fontSize: "2vh",
                }}
                // className="responsive_name_text"
                color={"text.primary"}
                // gutterBottom
                title={`${data?.firstName ?? ""} ${data?.lastName ?? ""}`}
              >
                {(() => {
                  const name = `${data?.firstName ?? ""} ${
                    data?.lastName ?? ""
                  }`;
                  return name?.length < 17
                    ? name ?? EMPTY_VALUE
                    : `${data?.firstName ?? EMPTY_VALUE} ${
                        data?.lastName ?? EMPTY_VALUE
                      }`.slice(0, 20) + "...";
                })()}
              </Typography>
              <Typography variant="caption">
                {" "}
                Profile ID - {data?.id ?? EMPTY_VALUE}
              </Typography>
              {/* <Typography
                  variant="h4"
                  sx={{ mt: 1.5, fontWeight: "600", textTransform: "uppercase" }}
                  className="responsive_name_text"
                  color={"text.primary"}
                  gutterBottom
            
                >
                  {/* <div
                    style={{
                      borderBottom: "1px solid black",
                      display: "inline-block",
                    }}
                  > */}
              {/* Profile ID:{data?.id ?? EMPTY_VALUE} */}
              {/* </div> */}
              {/* </Typography> */}
              <Typography color="black" variant="caption" fontWeight={600}>
                {data?.location ?? "-"}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{ width: "unset" }}
                  variant="caption"
                  color="#000000"
                >
                  Education&nbsp;
                </Typography>
                <Typography
                  sx={{ width: "5%" }}
                  variant="caption"
                  color="#000000"
                >
                  {" : "}
                </Typography>
                <Typography
                  sx={{ width: "45%", fontWeight: "bolder", wordBreak: "break-word" }}
                  variant="caption"
                  color="#000000"
                  align="left"
                >
                  {" " + data?.higherQualification ?? EMPTY_VALUE}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{ width: "17vw" }}
                  variant="caption"
                  color="#000000"
                >
                  Age
                </Typography>
                <Typography
                  sx={{ width: "5%" }}
                  variant="caption"
                  color="#000000"
                >
                  {" : "}
                </Typography>
                <Typography
                  sx={{ width: "45%", fontWeight: "bolder" }}
                  variant="caption"
                  color="#000000"
                  textAlign={"right"}
                >
                  {data?.age.split(" ").slice(0, 2).join(" ") ?? EMPTY_VALUE}
                </Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
        <Divider sx={{ width: "100%", borderColor: "#fff" }} />
        {type === INVITE_STATUS_ENUM.RECEIVED ? (
          data?.invitationStatus === INVITE_STATUS_ENUM.USER_ACCEPTED ||
          data?.invitationStatus === INVITE_STATUS_ENUM.SYSTEM_ACCEPTED ? (
            ShareActionContext
          ) : data?.invitationStatus === INVITE_STATUS_ENUM.REJECTED ? (
            RejectedActionContext
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
                position: "relative",
                width: "100%",
              }}
            >
              <Button
                variant="text"
                sx={{
                  color: "#F86969",
                  fontWeight: "bold",
                  borderRight: "1px solid #fff",
                }}
                fullWidth
                disableElevation={true}
                onClick={() => {
                  props?.RejectInvite();
                }}
              >
                DECLINE
              </Button>
              <Button
                variant="text"
                sx={{
                  color: "#00AA44",
                  fontWeight: "bold",
                }}
                fullWidth
                disableElevation={true}
                onClick={() => {
                  props?.AcceptInvite();
                }}
              >
                ACCEPT
              </Button>
            </Box>
          )
        ) : data?.invitationStatus === INVITE_STATUS_ENUM.USER_ACCEPTED ||
          data?.invitationStatus === INVITE_STATUS_ENUM.SYSTEM_ACCEPTED ? (
          ShareActionContext
        ) : data?.invitationStatus === INVITE_STATUS_ENUM.REJECTED ? (
          RejectedActionContext
        ) : (
          CancelRequestActionContext
        )}
      </Card>
    </Spinner>
  );
}
// function Desktop({ ...props }) {
//   const ShareActionContext = (
//     <CardContent
//       sx={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         flexDirection: "column",
//         width: "20%",
//         position: "relative",
//         height: "10em",
//         backgroundColor: "rgba(0,170,68,0.25)",
//         cursor: "pointer",
//       }}
//       onClick={() => {
//         props?.fetchContacts();
//       }}
//     >
//       <>
//         <Box
//           sx={{
//             padding: 2.5,
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             flexDirection: "column",
//           }}
//         >
//           <img alt="ShareIcon" style={{ width: 50 }} src={ShareIcon} />
//         </Box>
//         <Typography
//           fontSize={"small"}
//           sx={{
//             width: "100%",
//             fontWeight: "bolder",
//             textAlign: "center",
//             color: "#000",
//           }}
//         >
//           VIEW CONTACT
//         </Typography>
//       </>
//     </CardContent>
//   );
//   const RejectedActionContext = (
//     <CardContent
//       sx={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         flexDirection: "column",
//         width: "20%",
//         position: "relative",
//         height: "10em",
//         // backgroundColor: "rgba(0,170,68,0.25)",
//         cursor: "pointer",
//       }}
//       onClick={() => {}}
//     >
//       <Typography
//         fontSize={"large"}
//         sx={{
//           width: "100%",
//           fontWeight: "bolder",
//           textAlign: "center",
//           color: "red",
//         }}
//       >
//         REJECTED
//       </Typography>
//     </CardContent>
//   );
//   return (
//     <Spinner loading={props?.loading}>
//       <Card
//         variant="outlined"
//         sx={{
//           minWidth: 275,
//           borderColor: "#707070",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "space-between",
//           flexDirection: "row",
//           width: "100%",
//         }}
//       >
//         <CardContent
//           sx={{
//             display: "flex",
//             alignItems: "flex-start",
//             justifyContent: "space-between",
//             flexDirection: "row",
//             width: "85%",
//             height: "10em",
//             position: "relative",
//             borderRight: "1px solid #000",
//           }}
//         >
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "space-between",
//               flexDirection: "row",
//             }}
//           >
//             {!!Object.keys(props?.data?.imageUrl ?? {})[0] ? (
//               <Avatar
//                 alt={"a"}
//                 sx={{ width: 100, height: 100, objectFit: "cover" }}
//                 src={
//                   props?.data?.imageUrl[Object.keys(props?.data?.imageUrl)[0]]
//                 }
//               />
//             ) : (
//               <Avatar
//                 alt={""}
//                 sx={{ width: 100, height: 100, objectFit: "cover" }}
//                 src={EMPTY_VALUE}
//               />
//             )}
//             <Box
//               sx={{
//                 display: "flex",
//                 alignItems: "flex-start",
//                 justifyContent: "space-around",
//                 flexDirection: "column",
//                 width: "100%",
//                 marginLeft: 3,
//               }}
//             >
//               <Typography
//                 variant="h6"
//                 sx={{ mt: 1.5, fontWeight: "bold", textTransform: "uppercase" }}
//                 color={"text.primary"}
//                 gutterBottom
//                 title={`${props?.data?.firstName ?? ""} ${
//                   props?.data?.lastName ?? ""
//                 }`}
//               >
//                 {(() => {
//                   const name = `${props?.data?.firstName ?? ""} ${
//                     props?.data?.lastName ?? ""
//                   }`;
//                   return name?.length < 17
//                     ? name ?? EMPTY_VALUE
//                     : `${props?.data?.firstName ?? EMPTY_VALUE} ${
//                         props?.data?.lastName ?? EMPTY_VALUE
//                       }`.slice(0, 20) + "...";
//                 })()}
//               </Typography>
//               <Typography color="black" variant="subtitle2" fontWeight={600}>
//                 {props?.data?.location ?? "-"}
//               </Typography>
//               <Box
//                 sx={{
//                   display: "flex",
//                   alignItems: "flex-start",
//                   justifyContent: "space-between",
//                   flexDirection: "row",
//                   width: "100%",
//                 }}
//               >
//                 <Typography
//                   sx={{ width: "80%" }}
//                   variant="caption"
//                   color="#000000"
//                 >
//                   Education
//                 </Typography>
//                 <Typography
//                   sx={{ width: "5%" }}
//                   variant="caption"
//                   color="#000000"
//                 >
//                   {" : "}
//                 </Typography>
//                 <Typography
//                   sx={{ width: "80%", fontWeight: "bolder" }}
//                   variant="caption"
//                   color="#000000"
//                 >
//                   {" " + props?.data?.higherQualification ?? EMPTY_VALUE}
//                 </Typography>
//               </Box>
//               <Box
//                 sx={{
//                   display: "flex",
//                   alignItems: "flex-start",
//                   justifyContent: "space-between",
//                   flexDirection: "row",
//                   width: "100%",
//                 }}
//               >
//                 <Typography
//                   sx={{ width: "80%" }}
//                   variant="caption"
//                   color="#000000"
//                 >
//                   Age
//                 </Typography>
//                 <Typography
//                   sx={{ width: "5%" }}
//                   variant="caption"
//                   color="#000000"
//                 >
//                   {" : "}
//                 </Typography>
//                 <Typography
//                   sx={{ width: "80%", fontWeight: "bolder" }}
//                   variant="caption"
//                   color="#000000"
//                 >
//                   {props?.data?.age ?? EMPTY_VALUE}
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//           <Box
//             sx={{
//               display: "flex",
//               alignItems: "flex-end",
//               justifyContent: "space-around",
//               flexDirection: "column",
//               marginRight: 3,
//             }}
//           >
//             {/* <Typography
//               fontFamily="Roboto"
//               fontWeight={600}
//               sx={{ my: 1.5 }}
//               color={"text.primary"}
//             >
//               <div
//                 style={{
//                   borderBottom: "1px solid black",
//                   display: "inline-block",
//                 }}
//               >
//                 Profile ID:{data?.id ?? EMPTY_VALUE}
//               </div>
//             </Typography> */}
//             <Button
//               variant="contained"
//               style={{
//                 height: "2.5em",
//                 fontWeight: 900,
//                 margin: "3em 0",
//                 fontSize: "10pt",
//                 displat: "block",
//               }}
//               size="large"
//               disableElevation={true}
//               onClick={() => {
//                 if (!!props?.data?.id) {
//                   props.history.push(`/my-matches/${props?.data?.id}`, {
//                     data: props?.data,
//                   });
//                 }
//               }}
//             >
//               Visit PROFILE
//             </Button>
//           </Box>
//         </CardContent>
//         {props?.type === INVITE_STATUS_ENUM.RECEIVED ? (
//           props?.data?.invitationStatus === INVITE_STATUS_ENUM.ACCEPTED ? (
//             ShareActionContext
//           ) : props?.data?.invitationStatus === INVITE_STATUS_ENUM.REJECTED ? (
//             RejectedActionContext
//           ) : (
//             <Box
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "space-between",
//                 flexDirection: "column",
//                 width: "20%",
//                 height: "10em",
//                 position: "relative",
//               }}
//             >
//               <Button
//                 variant="text"
//                 sx={{
//                   color: "#00AA44",
//                   fontWeight: "bold",
//                   padding: 3,
//                 }}
//                 fullWidth
//                 disableElevation={true}
//                 onClick={() => {
//                   props?.AcceptInvite();
//                 }}
//               >
//                 ACCEPT
//               </Button>
//               <Divider sx={{ width: "100%", borderColor: "#000" }} />
//               <Button
//                 variant="text"
//                 sx={{
//                   color: "#F86969",
//                   fontWeight: "bold",
//                   padding: 3,
//                 }}
//                 fullWidth
//                 disableElevation={true}
//                 onClick={() => {
//                   props?.RejectInvite();
//                 }}
//               >
//                 Reject
//               </Button>
//             </Box>
//           )
//         ) : props?.data?.invitationStatus === INVITE_STATUS_ENUM.ACCEPTED ? (
//           ShareActionContext
//         ) : props?.data?.invitationStatus === INVITE_STATUS_ENUM.REJECTED ? (
//           RejectedActionContext
//         ) : (
//           <CardContent
//             sx={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               flexDirection: "column",
//               width: "20%",
//               position: "relative",
//               height: "10em",
//               backgroundColor: "rgba(248,105,105, 0.40)",
//               cursor: "pointer",
//             }}
//             onClick={() => {
//               props?.CancelInvite();
//             }}
//           >
//             <Typography
//               fontSize={"large"}
//               sx={{
//                 width: "100%",
//                 fontWeight: "bolder",
//                 textAlign: "center",
//                 color: "#fff",
//               }}
//             >
//               CANCEL REQUEST
//             </Typography>
//           </CardContent>
//         )}
//       </Card>
//       {!!props?.isInviteSharedModalOpen && (
//         <InviteSharedInfoModal
//           open={props?.isInviteSharedModalOpen}
//           data={props?.contactResp}
//           onClose={() => {
//             props?.toggleInviteSharedModal(false);
//           }}
//           next={() => {
//             props?.toggleInviteSharedModal(false);
//           }}
//         />
//       )}
//     </Spinner>
//   );
// }

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchCancelMatchInvite: (id, onSuccess, onError) =>
    dispatch(CancelMatchInvite(id, onSuccess, onError)),
  dispatchFetchContacts: (id, onSuccess, onError) =>
    dispatch(FetchContacts(id, onSuccess, onError)),
  dispatchAcceptMatchInvite: (id, onSuccess, onError) =>
    dispatch(AcceptMatchInvite(id, onSuccess, onError)),
  dispatchRejectMatchInvite: (id, onSuccess, onError) =>
    dispatch(RejectMatchInvite(id, onSuccess, onError)),
  dispatchFetchPrimeContacts: (id, onSuccess, onError) =>
    dispatch(FetchPrimeContacts(id, onSuccess, onError)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InboxUserCard));
