import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { withRouter } from "react-router-dom";
import { EMPTY_VALUE } from "../../Utils/constants";
import ChatIcon from "@mui/icons-material/Chat";
import LoadingUserCard from "./LoadingProfileCard";
import { ChatWindowLauncher } from "../../Chat";

function UserProfileCard({ data, disabled, loading = false, ...props }) {
  const {
    id,
    firstName,
    age,
    higherQualification,
    imageUrl,
    language,
    location,
    filterPayload,
    isPrime,
    disabledChat,
  } = data ?? {};
  if (loading) {
    return <LoadingUserCard />;
  }
  return (
    <Card
      key={id}
      variant="outlined"
      sx={{
        minWidth: 250,
        borderColor: "rgba(102,51,153, 0.5)",
        borderRadius: "15px",
        cursor: "pointer",
      }}
      onClick={() => {
        if (loading) return;
        if (!!id)
          props.history.push(`/my-matches/${id}`, {
            data,
            filterInfo: filterPayload,
            pathname: "/my-matches/",
          });
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          backgroundColor: disabled
            ? "rgba(112,112,112, 0.25)"
            : "rgba(255,39,183, 0.1)",
          width: "100%",
          position: "relative",
        }}
      >
        {isPrime && (
          <Chip label="PRIME" sx={{ position: "absolute", right: 5, top: 5 }} />
        )}
        {!!Object.keys(imageUrl)[0] ? (
          <Avatar
            alt={data?.firstName ?? "a"}
            sx={{ width: 150, height: 150, objectFit: "cover" }}
            src={imageUrl[Object.keys(imageUrl)[0]]}
          />
        ) : (
          <Avatar
            alt={firstName}
            sx={{ width: 150, height: 150, objectFit: "cover" }}
            src={""}
          />
        )}
        <Typography
          variant="h6"
          sx={{ mt: 1.5, fontWeight: "bold", textTransform: "uppercase" }}
          color={disabled ? "#000" : "text.primary"}
          gutterBottom
          title={`${data?.firstName ?? ""} ${data?.lastName ?? ""}`}
        >
          {(() => {
            const name = `${data?.firstName ?? ""} ${data?.lastName ?? ""}`;
            return name?.length < 17
              ? name ?? EMPTY_VALUE
              : `${data?.firstName ?? EMPTY_VALUE} ${
                  data?.lastName ?? EMPTY_VALUE
                }`.slice(0, 15) + "...";
          })()}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            width: "inherit",
          }}
        >
          <Typography variant="body2" color="#000000">
            <u>Profile ID : {id ?? EMPTY_VALUE}</u>
          </Typography>
          <Tooltip
            disableFocusListener
            disableTouchListener
            title={disabledChat ? "Update to Prime" : ""}
            placement="top"
          >
            <ChatWindowLauncher
              userProfile={{
                id: data?.id,
                teamName: `${data?.firstName ?? ""} ${data?.lastName ?? ""}`,
                imageUrl: Object.values(imageUrl)[0],
              }}
              disabled={disabledChat}
            >
              <Button
                loading={true}
                sx={{
                  mt: 1,
                  width: "100%",
                  borderRadius: 2,
                  textTransform: "none",
                  borderColor: "#663399",
                  color: "#663399",
                  "&:hover": {
                    borderColor: "#663399",
                    backgroundColor: "#663399",
                    color: "#fff",
                  },
                }}
                variant="outlined"
                disabled={disabledChat}
              >
                Chat
              </Button>
            </ChatWindowLauncher>
          </Tooltip>
        </Box>
      </CardContent>
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Typography sx={{ width: "33%" }} variant="caption" color="#000000">
            Location
          </Typography>
          <Typography variant="caption" color="#000000">
            {" - "}
          </Typography>
          <Typography
            sx={{ width: "50%", fontWeight: "bolder" }}
            variant="caption"
            color="#000000"
          >
            {location ?? EMPTY_VALUE}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Typography sx={{ width: "33%" }} variant="caption" color="#000000">
            Education
          </Typography>
          <Typography variant="caption" color="#000000">
            {" - "}
          </Typography>
          <Typography
            sx={{ width: "50%", fontWeight: "bolder" }}
            variant="caption"
            color="#000000"
          >
            {higherQualification ?? EMPTY_VALUE}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Typography sx={{ width: "33%" }} variant="caption" color="#000000">
            Age
          </Typography>
          <Typography variant="caption" color="#000000">
            {" - "}
          </Typography>
          <Typography
            sx={{ width: "50%", fontWeight: "bolder" }}
            variant="caption"
            color="#000000"
          >
            {age ?? EMPTY_VALUE}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default withRouter(UserProfileCard);
